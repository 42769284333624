import { directus } from "./directus";

export const ApiUtils = {
  call: async (callFunc) => {
    try {
      const res = await callFunc();
      return {
        success: true,
        data: res,
      };
    } catch (error) {
      const errorJson = error?.response?.errors
        ? error?.response?.errors[0]
        : error?.response?.raw;
      return {
        success: false,
        error: errorJson,
      };
    }
  },
  get: async (path, options = {}) => {
    const { params = null, useAuth = true } = options;
    const apiOptions = {};
    if (useAuth) {
      apiOptions["headers"] = {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      };
    }
    if (params) {
      apiOptions["params"] = params;
    }
    try {
      const res = await directus.transport.get(path, apiOptions);
      return {
        success: true,
        data: res?.raw,
      };
    } catch (error) {
      const errorJson = error?.response?.errors
        ? error?.response?.errors[0]
        : error?.response?.raw;
      return {
        success: false,
        error: errorJson,
      };
    }
  },
  post: async (path, options = {}) => {
    const { body = null, useAuth = true } = options;
    const apiOptions = {};
    if (useAuth) {
      apiOptions["headers"] = {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      };
    }
    try {
      const res = await directus.transport.post(path, body, apiOptions);
      return {
        success: true,
        data: res?.raw,
      };
    } catch (error) {
      const errorJson = error?.response?.errors
        ? error?.response?.errors[0]
        : error?.response?.raw;
      return {
        success: false,
        error: errorJson,
      };
    }
  },
  axios: async (axiosFunc) => {
    try {
      const { data } = await axiosFunc();
      return {
        success: true,
        data,
      };
    } catch (error) {
      return {
        success: false,
        error: error?.response?.data?.error?.message,
      };
    }
  },
};

export const ApiHelper = {
  getRegions: () =>
    ApiUtils.call(() =>
      directus.items("addresses").readByQuery({
        groupBy: "region",
        sort: "region",
        limit: -1,
      })
    ),

  getProvinces: ({ regions = [] }) => {
    const filter = {};
    if (regions.length) {
      filter["region"] = { _in: regions };
    }
    return ApiUtils.call(() =>
      directus.items("addresses").readByQuery({
        groupBy: "province_id, province_th",
        sort: "province_id",
        limit: -1,
        filter,
      })
    );
  },
  getDistricts: ({ regions = [], provinces = [] }) => {
    const filter = {};
    if (regions.length) {
      filter["region"] = { _in: regions };
    }
    if (provinces.length) {
      filter["province_th"] = { _in: provinces };
    }
    return ApiUtils.call(() =>
      directus.items("addresses").readByQuery({
        groupBy: "district_id, district_th_short",
        sort: "district_id",
        limit: -1,
        filter,
      })
    );
  },
  getTambons: ({ regions = [], provinces = [], districts = [] }) => {
    let filter = {};
    if (regions.length) {
      filter["region"] = { _in: regions };
    }
    if (provinces.length) {
      filter["province_th"] = { _in: provinces };
    }
    if (districts.length) {
      filter["district_th_short"] = { _in: districts };
    }
    return ApiUtils.call(() =>
      directus.items("addresses").readByQuery({
        groupBy: "tambon_id, tambon_th_short",
        sort: "tambon_id",
        limit: -1,
        filter,
      })
    );
  },

  getCrimeReport: ({
    duration,
    regions,
    provinces,
    districts,
    tambons,
    genders,
    damages,
  }) =>
    ApiUtils.post("/crime-report", {
      body: {
        duration,
        regions,
        provinces,
        districts,
        tambons,
        genders,
        damages,
      },
    }),

  getEventReport: ({
    duration,
    regions,
    provinces,
    districts,
    tambons,
    genders,
    damages,
    types,
  }) =>
    ApiUtils.post("/event-report", {
      body: {
        duration,
        regions,
        provinces,
        districts,
        tambons,
        genders,
        damages,
        types,
      },
    }),

  getCrimeVerifyReport: ({
    // duration,
    startDate,
    endDate,
    regions,
    provinces,
    districts,
    tambons,
    genders,
    damages,
    verified,
    types,
  }) =>
    ApiUtils.post("/verify-pie-type", {
      body: {
        // duration,
        startDate,
        endDate,
        regions,
        provinces,
        districts,
        tambons,
        genders,
        damages,
        verified,
        types,
      },
    }),

  getCrimeGenderReport: ({
    // duration,
    startDate,
    endDate,
    regions,
    provinces,
    districts,
    tambons,
    genders,
    damages,
    verified,
    types,
  }) =>
    ApiUtils.post("/verify-pie-gender", {
      body: {
        // duration,
        startDate,
        endDate,
        regions,
        provinces,
        districts,
        tambons,
        genders,
        damages,
        verified,
        types,
      },
    }),

  getEventLineReport: ({
    // duration,
    startDate,
    endDate,
    regions,
    provinces,
    districts,
    tambons,
    genders,
    damages,
    verified,
  }) =>
    ApiUtils.post("/verify-line-type", {
      body: {
        // duration,
        startDate,
        endDate,
        regions,
        provinces,
        districts,
        tambons,
        genders,
        damages,
        verified,
      },
    }),

  getEventRegionReport: ({
    // duration,
    startDate,
    endDate,
    regions,
    provinces,
    districts,
    tambons,
    genders,
    damages,
    verified,
    types,
  }) =>
    ApiUtils.post("/verify-line-region", {
      body: {
        // duration,
        startDate,
        endDate,
        regions,
        provinces,
        districts,
        tambons,
        genders,
        damages,
        verified,
        types,
      },
    }),

  getCrimeNotiReport: ({
    // duration,
    startDate,
    endDate,
    regions,
    provinces,
    districts,
    tambons,
    genders,
    damages,
    types,
  }) =>
    ApiUtils.post("/emergency-pie", {
      body: {
        // duration,
        startDate,
        endDate,
        regions,
        provinces,
        districts,
        tambons,
        genders,
        damages,
        types,
      },
    }),

  getEventTypeReport: ({
    // duration,
    startDate,
    endDate,
    regions,
    provinces,
    districts,
    tambons,
    genders,
    damages,
    types,
  }) =>
    ApiUtils.post("/emergency-line", {
      body: {
        // duration,
        startDate,
        endDate,
        regions,
        provinces,
        districts,
        tambons,
        genders,
        damages,
        types,
      },
    }),

  getEmergencyRegionReport: ({
    // duration,
    startDate,
    endDate,
    regions,
    provinces,
    districts,
    tambons,
    genders,
    damages,
    types,
  }) =>
    ApiUtils.post("/emergency-line-region", {
      body: {
        // duration,
        startDate,
        endDate,
        regions,
        provinces,
        districts,
        tambons,
        genders,
        damages,
        types,
      },
    }),
};
