import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../libs/auth";
import {
  Layout,
  AddressFilter,
  TypeFilter2,
  VerifyRegionDashboard,
  VerifyPieDashboard,
  VerifyTypeDashboard,
} from "../../components";

const CrimeVerify = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [addressFilter, setAddressFilter] = useState(null);
  const [typeFilter2, setTypeFilter2] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Layout>
      <h4 className="m-3">
        Dashboard แสดงจำนวนการแจ้งข้อมูลข่าวสารเพื่อความปลอดภัยจากแอปพลิเคชัน
      </h4>
      <AddressFilter onChange={(value) => setAddressFilter(value)} />
      <TypeFilter2 onChange={(value) => setTypeFilter2(value)} />
      <VerifyPieDashboard
        addressFilter={addressFilter}
        typeFilter={typeFilter2}
      />
      <div className="d-flex flex-row justify-content-center m-4">
        <VerifyTypeDashboard
          addressFilter={addressFilter}
          typeFilter={typeFilter2}
        />
        <VerifyRegionDashboard
          addressFilter={addressFilter}
          typeFilter={typeFilter2}
        />
      </div>
    </Layout>
  );
};

export default CrimeVerify;
