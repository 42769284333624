import { directus } from "./directus";

import { createContext, useState, useEffect } from "react";
import { BOARD_ROLE } from "./constants";
import { ApiUtils } from "./api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const checkAuth = async () => {
    const authToken = localStorage.getItem("auth_token");
    if (authToken) {
      const { success, data, error } = await AuthHelper.me();
      if (success) {
        setUser(data);
        return { success };
      } else if (error === "You are not authorized to access this page") {
        const { success: logoutSuccess } = await AuthHelper.logout();
        if (!logoutSuccess) {
          localStorage.removeItem("auth_token");
        }
        setUser(null);
      }
      return { success, error };
    } else {
      setUser(null);
      return { success: false };
    }
  };

  useEffect(() => {
    window.addEventListener("storage", checkAuth);
    checkAuth();
    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthHelper = {
  login: (email, password) =>
    ApiUtils.call(() =>
      directus.auth.login({
        email: email,
        password: password,
      })
    ),
  me: async (metaData) => {
    const resMe = await ApiUtils.call(() => directus.users.me.read(metaData));
    if (resMe?.success) {
      if (resMe?.data?.role.toLowerCase() === BOARD_ROLE.toLowerCase()) {
        return {
          success: true,
          data: resMe?.data,
        };
      } else {
        return {
          success: false,
          error: "You are not authorized to access this page",
        };
      }
    } else {
      return {
        success: false,
        error: resMe?.error,
      };
    }
  },
  setToken: async (token) => {
    await directus.auth.static(token);
  },
  getToken: () => {
    return directus.auth.token;
  },
  logout: () => ApiUtils.call(() => directus.auth.logout()),
};
