import CookieConsent from "react-cookie-consent";

import { Navbar, Footer } from ".";

const Layout = ({ children }) => {
  return (
    <div className="vh-100">
      <div className="d-flex flex-column vh-100">
        <header>
          <Navbar />
        </header>
        <div className="d-flex flex-column flex-grow-1">
          <main className="main-container flex-grow-1">{children}</main>
          <footer>
            <Footer />
          </footer>
        </div>
        <CookieConsent buttonText="รับทราบ" cookieName="acceptedCookieConsent">
          เว็บไซต์นี้มีการใช้งานคุกกี้เพื่อเพิ่มความสะดวกในการใช้งานระบบ
        </CookieConsent>
      </div>
    </div>
  );
};

export default Layout;
