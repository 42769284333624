import React, { useState, useEffect } from "react";
import {
  VictoryPie,
  VictoryLabel,
  VictoryLegend,
  VictoryTooltip,
} from "victory";
import { ApiHelper } from "../libs/api";
import { MDBIcon, MDBTooltip } from "mdb-react-ui-kit";

const genderColorMapping = {
  เพศชาย: "#3070F5",
  เพศหญิง: "#AE59DC",
  ไม่ระบุเพศ: "#57BEB5",
};

const PieNotiDashboard = ({ addressFilter = {}, typeFilter = {} }) => {
  const [crimeVerifyData, setCrimeVerifyData] = useState([]);
  const [totalVerifyCases, setVerifyCases] = useState(0);
  const [crimeGenderData, setCrimeGenderData] = useState([]);
  const [totalGenderCases, setTotalGenderCases] = useState(0);

  const fetchCrimeVerifyData = async ({
    regions = [],
    provinces = [],
    districts = [],
    tambons = [],
    peopleTypes = [],
    damageTypes = [],
    verifyTypes = [],
    eventTypes = [],
    startDate,
    endDate,
  }) => {
    try {
      const response = await ApiHelper.getCrimeVerifyReport({
        regions,
        provinces,
        districts,
        tambons,
        genders: peopleTypes,
        damages: damageTypes,
        verified: verifyTypes,
        types: eventTypes,
        startDate,
        endDate,
      });
      if (response.success) {
        const verifiedData = {
          x: "ยืนยันแล้ว",
          y: response.data.verified || 0,
        };
        const unverifiedData = {
          x: "ยังไม่ยืนยัน",
          y: response.data.unverified || 0,
        };

        setCrimeVerifyData([verifiedData, unverifiedData]);
        setVerifyCases(response.data.total || 0);
      } else {
        console.error("Error fetching crime case data:", response.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchCrimeGenderData = async ({
    regions = [],
    provinces = [],
    districts = [],
    tambons = [],
    peopleTypes = [],
    damageTypes = [],
    verifyTypes = [],
    eventTypes = [],
    startDate,
    endDate,
  }) => {
    try {
      const response = await ApiHelper.getCrimeGenderReport({
        regions,
        provinces,
        districts,
        tambons,
        genders: peopleTypes,
        damages: damageTypes,
        verified: verifyTypes,
        types: eventTypes,
        startDate,
        endDate,
      });

      if (response.success) {
        let genderCounts = {
          เพศชาย: 0,
          เพศหญิง: 0,
          ไม่ระบุเพศ: 0,
        };

        if (peopleTypes.length > 0) {
          genderCounts = peopleTypes.reduce((acc, type) => {
            if (type === "male") acc["เพศชาย"] = 0;
            else if (type === "female") acc["เพศหญิง"] = 0;
            else if (type === "none") acc["ไม่ระบุเพศ"] = 0;
            return acc;
          }, {});
        }

        let totalCount = 0;
        Object.values(response.data).forEach((genderData) => {
          Object.entries(genderData).forEach(([gender, count]) => {
            const genderLabel =
              gender === "male"
                ? "เพศชาย"
                : gender === "female"
                ? "เพศหญิง"
                : "ไม่ระบุเพศ";
            if (genderCounts.hasOwnProperty(genderLabel)) {
              genderCounts[genderLabel] += count;
              totalCount += count;
            }
          });
        });

        const newDataArray = Object.entries(genderCounts).map(
          ([gender, count]) => ({
            x: gender,
            y: count,
          })
        );

        setCrimeGenderData(newDataArray);
        setTotalGenderCases(totalCount);
      } else {
        console.error("Error fetching crime gender data:", response.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchReport = async (filter = {}) => {
    fetchCrimeVerifyData(filter);
    fetchCrimeGenderData(filter);
  };

  useEffect(() => {
    const filter = { ...addressFilter, ...typeFilter };
    if (Object.keys(filter).length) {
      fetchReport(filter);
    }
  }, [addressFilter, typeFilter]);

  const getVerificationTooltipContent = ({ datum }) => {
    const percentage = ((datum.y / totalVerifyCases) * 100).toFixed(2);
    const thaiName = datum.x === "ยืนยันแล้ว" ? "ยืนยันแล้ว" : "ยังไม่ยืนยัน";
    return `${thaiName}: ${datum.y} ครั้ง (${percentage}%)`;
  };

  const getGenderTooltipContent = ({ datum }) => {
    let thaiName;
    switch (datum.x) {
      case "male":
        thaiName = "ชาย";
        break;
      case "female":
        thaiName = "หญิง";
        break;
      case "unspecified":
        thaiName = "ไม่ระบุ";
        break;
      default:
        thaiName = datum.x;
    }
    const percentage = ((datum.y / totalGenderCases) * 100).toFixed(2);
    return `${thaiName}: ${datum.y} คน (${percentage}%)`;
  };

  return (
    <div className="d-flex flex-row justify-content-center m-4">
      <div className="w-50 me-4">
        <h5>
          จำนวนการยืนยันการแจ้งข้อมูลข่าวสารเพื่อความปลอดภัย โดยเจ้าหน้าที่{" "}
        </h5>
        <div style={{ height: "400px", width: "500px" }}>
          <svg viewBox="0 0 500 500">
            <VictoryPie
              standalone={false}
              data={crimeVerifyData}
              innerRadius={90}
              labelRadius={150}
              labelComponent={
                <VictoryTooltip
                  flyoutStyle={{ fill: "white" }}
                  center={{ x: 200 }}
                />
              }
              labels={getVerificationTooltipContent}
              style={{
                data: {
                  fillOpacity: 0.9,
                  stroke: "#fff",
                  strokeWidth: 2,
                },
              }}
              colorScale={["#57BEB5", "#3070F5"]}
            />
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="middle"
              x={200}
              y={200}
              text={`จำนวนทั้งหมด(ครั้ง)\n${totalVerifyCases}`}
              style={{ fontSize: 16 }}
            />
            <g transform={`translate(${380}, ${20})`}>
              <VictoryLegend
                standalone={false}
                orientation="vertical"
                gutter={20}
                style={{
                  border: { stroke: "black" },
                  labels: { fontSize: 14 },
                  title: { fontSize: 14 },
                }}
                data={[
                  { name: "ยืนยันแล้ว", symbol: { fill: "#57BEB5" } },
                  { name: "ยังไม่ยืนยัน", symbol: { fill: "#3070F5" } },
                ]}
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="w-50">
        <h5>
          จำนวนคนที่แจ้งข้อมูลข่าวสารเพื่อความปลอดภัย แยกตามเพศ{" "}
          <MDBTooltip
            tag="span"
            wrapperClass="text-primary"
            innerClass="text-primary"
            title={
              <div className="text-nowrap">
                <div className="d-flex flex-column align-items-center">
                  <p className="mb-0">
                    ข้อมูลจำนวนแจ้งเหตุจากประชาชนผ่านแอปพลิเคชั่น Safe Points
                    แยกตามเพศผู้แจ้งเหตุ
                  </p>
                </div>
              </div>
            }
          >
            <MDBIcon fas icon="info-circle" size="sm" />
          </MDBTooltip>
        </h5>
        <div style={{ height: "400px", width: "500px" }}>
          <svg viewBox="0 0 500 500">
            <VictoryPie
              standalone={false}
              data={crimeGenderData}
              innerRadius={90}
              labelRadius={150}
              labelComponent={
                <VictoryTooltip
                  flyoutStyle={{ fill: "white" }}
                  center={{ x: 190 }}
                />
              }
              labels={getGenderTooltipContent}
              style={{
                data: {
                  fill: ({ datum }) => genderColorMapping[datum.x],
                  stroke: "#fff",
                  strokeWidth: 2,
                },
              }}
            />
            <VictoryLabel
              textAnchor="middle"
              verticalAnchor="middle"
              style={{ fontSize: 16 }}
              x={200}
              y={200}
              text={`จำนวนทั้งหมด(คน)\n${totalGenderCases}`}
            />
            {/* Positioning the legend to top right */}
            <g transform={`translate(${390}, ${20})`}>
              <VictoryLegend
                standalone={false}
                orientation="vertical"
                gutter={20}
                style={{
                  border: { stroke: "black" },
                  labels: { fontSize: 14 },
                  title: { fontSize: 14 },
                }}
                data={Object.entries(genderColorMapping).map(
                  ([gender, color]) => ({
                    name: gender,
                    symbol: { fill: color },
                  })
                )}
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default PieNotiDashboard;
