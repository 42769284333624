import { useState, useEffect } from "react";
import {
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLegend,
  VictoryAxis,
} from "victory";
import { MDBTooltip, MDBIcon } from "mdb-react-ui-kit";
import { ApiHelper } from "../libs/api";
const CrimeTypes = {
  life: "ชีวิตและร่างกาย",
  sex: "เพศ",
  property: "ทรัพย์",
  drug: "ยาเสพติด",
  custom: "อื่นๆ",
};
const CrimeTypesColor = {
  life: "#00798C", // green
  sex: "#E63946", //red
  property: "#EDAE49", //yellow
  drug: "#52489C", //purple
  custom: "#3376BD", //blue
};
const HomeTypeDashboard = ({ addressFilter = {}, typeFilter = {} }) => {
  const [crimeChartData, setCrimeChartData] = useState({});
  const [crimeReport, setCrimeReport] = useState({});
  const fetchReport = async (filter) => {
    const {
      regions = [],
      provinces = [],
      districts = [],
      tambons = [],
      duration = 6,
      peopleTypes = [],
      damageTypes = [],
    } = filter;
    const p1 = new Promise((resolve, reject) => {
      ApiHelper.getCrimeReport({
        duration,
        regions,
        provinces,
        districts,
        tambons,
        genders: peopleTypes,
        damages: damageTypes,
      }).then(({ success, data }) => {
        if (success) {
          resolve(data);
        } else {
          resolve(null);
        }
      });
    });
    const reports = await Promise.all([p1]);
    setCrimeReport(reports[0]);
  };

  const getChartData = (report) => {
    const chartData = {};
    if (Object.keys(report).length) {
      const months = Object.keys(report).sort();
      for (let month of months) {
        if ("crime_count" in report[month]) {
          for (let type in report[month].crime_count) {
            if (!(type in chartData)) {
              chartData[type] = [];
            }
            const y = parseInt(month.split("-")[0]);
            const m = parseInt(month.split("-")[1]);
            chartData[type].push({
              x: new Date(y, m - 1, 1),
              y: report[month].crime_count[type],
              type: CrimeTypes[type],
            });
          }
        }
      }
    }
    return chartData;
  };

  const getXDomain = () => {
    const minDate = new Date();
    if (typeFilter?.duration) {
      minDate.setMonth(minDate.getMonth() - typeFilter?.duration);
    }
    minDate.setDate(1);
    const maxDate = new Date();
    maxDate.setDate(1);
    return { x: [minDate, maxDate] };
  };
  const getHoverLabel = (datum) => {
    if (datum.childName.includes("line")) {
      if (typeFilter?.eventTypes?.length) {
        if (CrimeTypes[typeFilter.eventTypes[0]] === datum.type) {
          const df = new Intl.DateTimeFormat("th-TH", {
            year: "numeric",
            month: "long",
            timeZone: "Asia/Bangkok",
          });
          return `เดือน ${df.format(datum.x)}`;
        } else {
          return null;
        }
      } else if (datum.type === CrimeTypes["drug"]) {
        const df = new Intl.DateTimeFormat("th-TH", {
          year: "numeric",
          month: "long",
          timeZone: "Asia/Bangkok",
        });
        return `เดือน ${df.format(datum.x)}`;
      } else {
        return null;
      }
    }
    return `${datum.type} ${datum.y} คดี`;
  };
  useEffect(() => {
    const filter = { ...addressFilter, ...typeFilter };
    if (Object.keys(filter).length) {
      fetchReport(filter);
    }
  }, [addressFilter, typeFilter]);

  useEffect(() => {
    const chartData = getChartData(crimeReport);
    if (
      typeFilter?.peopleTypes?.length > 0 ||
      typeFilter?.damageTypes?.length > 0
    ) {
      setCrimeChartData({});
    } else {
      setCrimeChartData(chartData);
    }
  }, [crimeReport]);

  return (
    <div className="w-50 me-4">
      <h4>
        {" "}
        จำนวนคดีการเกิดอาชญากรรม{" "}
        <MDBTooltip
          tag="span"
          wrapperClass="text-primary"
          innerClass="text-primary"
          title={
            <div className="text-nowrap">
              <div className="d-flex flex-column align-items-start">
                <strong>ข้อมูลอัพเดตล่าสุด</strong>
                <li>เดือน เมษายน 2566</li>
                <strong>จากแหล่งข้อมูลดังนี้</strong>
                <li>สำนักงานตำรวจแห่งชาติ</li>
              </div>
            </div>
          }
        >
          <MDBIcon fas icon="info-circle" size="sm" />
        </MDBTooltip>
      </h4>
      <VictoryChart
        width={600}
        height={400}
        scale={{ x: "time" }}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => getHoverLabel(datum)}
            labelComponent={
              <VictoryTooltip
                cornerRadius={0}
                flyoutStyle={{ fill: "white" }}
              />
            }
          />
        }
      >
        <VictoryLegend
          x={100}
          y={0}
          orientation="horizontal"
          gutter={20}
          style={{ border: { stroke: "black" }, title: { fontSize: 20 } }}
          data={Object.keys(CrimeTypes).map((type) => ({
            name: CrimeTypes[type],
            symbol: { fill: CrimeTypesColor[type] },
          }))}
        />
        {Object.keys(crimeChartData).length === 0 &&
          (typeFilter?.peopleTypes?.length > 0 ||
            typeFilter?.damageTypes?.length > 0) && (
            <VictoryLegend
              x={150}
              y={150}
              gutter={20}
              style={{ border: { stroke: "black" }, title: { fill: "red" } }}
              title="ไม่สามารถใช้งานการกรองดังต่อไปนี้กับกราฟนี้ได้"
              centerTitle
              data={[
                {
                  name: "คุณลักษณะประชาชน",
                  symbol: { fill: "red" },
                  labels: { fill: "red" },
                },
                {
                  name: "ความรุนแรง",
                  symbol: { fill: "red" },
                  labels: { fill: "red" },
                },
              ]}
            />
          )}
        <VictoryAxis
          domain={getXDomain()}
          tickFormat={(x) => {
            const df = new Intl.DateTimeFormat("th-TH", {
              year: "2-digit",
              month: "short",
              timeZone: "Asia/Bangkok",
            }); //YYYY-MM-DD
            return df.format(x);
          }}
        />
        <VictoryAxis dependentAxis domain={{ y: [0, 10] }} />
        <VictoryGroup>
          {Object.keys(crimeChartData).map((type, idx) => {
            if (typeFilter?.eventTypes?.length) {
              if (typeFilter?.eventTypes?.includes(type)) {
                return (
                  <VictoryLine
                    key={idx}
                    data={crimeChartData[type]}
                    style={{ data: { stroke: CrimeTypesColor[type] } }}
                  />
                );
              }
              return null;
            }
            return (
              <VictoryLine
                key={idx}
                data={crimeChartData[type]}
                style={{ data: { stroke: CrimeTypesColor[type] } }}
              />
            );
          })}
        </VictoryGroup>
        {Object.keys(crimeChartData).map((type, idx) => {
          if (typeFilter?.eventTypes?.length) {
            if (typeFilter?.eventTypes?.includes(type)) {
              return (
                <VictoryScatter
                  key={idx}
                  data={crimeChartData[type]}
                  size={3}
                  style={{ data: { fill: CrimeTypesColor[type] } }}
                />
              );
            }
            return null;
          }
          return (
            <VictoryScatter
              key={idx}
              data={crimeChartData[type]}
              size={3}
              style={{ data: { fill: CrimeTypesColor[type] } }}
            />
          );
        })}
      </VictoryChart>
    </div>
  );
};
export default HomeTypeDashboard;
