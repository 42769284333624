import { useState, useEffect } from "react";
import {
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLegend,
  VictoryAxis,
} from "victory";
import { ApiHelper } from "../libs/api";

const CrimeTypes = {
  life: "ชีวิตและร่างกาย",
  sex: "เพศ",
  property: "ทรัพย์",
  drug: "ยาเสพติด",
  custom: "อื่นๆ",
};

const CrimeTypesColor = {
  life: "#00798C", // green
  sex: "#E63946", //red
  property: "#EDAE49", //yellow
  drug: "#52489C", //purple
  custom: "#3376BD", //blue
};

const LineNotiDashboard = ({ addressFilter = {}, typeFilter = {} }) => {
  const [eventChartData, setEventChartData] = useState({});
  const [eventReport, setEventReport] = useState({});

  const fetchReport = async (filter) => {
    const {
      regions = [],
      provinces = [],
      districts = [],
      tambons = [],
      peopleTypes = [],
      damageTypes = [],
      verifyTypes = [],
      startDate,
      endDate,
    } = filter;
    const p1 = new Promise((resolve, reject) => {
      ApiHelper.getEventLineReport({
        regions,
        provinces,
        districts,
        tambons,
        genders: peopleTypes,
        damages: damageTypes,
        verified: verifyTypes,
        startDate,
        endDate,
      }).then(({ success, data }) => {
        if (success) {
          resolve(data);
        } else {
          resolve(null);
        }
      });
    });
    const reports = await Promise.all([p1]);
    setEventReport(reports[0]);
  };

  const getChartData = (report) => {
    const chartData = {};
    if (Object.keys(report).length) {
      const months = Object.keys(report).sort();
      for (let month of months) {
        if ("crime_count" in report[month]) {
          for (let type in report[month].crime_count) {
            if (!(type in chartData)) {
              chartData[type] = [];
            }
            const y = parseInt(month.split("-")[0]);
            const m = parseInt(month.split("-")[1]);
            const date = new Date(y, m - 1, 1);
            chartData[type].push({
              x: date,
              y: report[month].crime_count[type],
              type: CrimeTypes[type],
            });
          }
        }
      }
    }
    return chartData;
  };

  const getXDomain = () => {
    const minDate = new Date();
    if (typeFilter?.duration) {
      minDate.setMonth(minDate.getMonth() - typeFilter?.duration);
    }
    minDate.setDate(1);

    const maxDate = typeFilter?.endDate
      ? new Date(typeFilter.endDate)
      : new Date();
    maxDate.setDate(1);

    return { x: [minDate, maxDate] };
  };

  const getHoverLabel = (datum) => {
    if (datum.childName.includes("line")) {
      if (typeFilter?.eventTypes?.length) {
        if (CrimeTypes[typeFilter.eventTypes[0]] === datum.type) {
          const df = new Intl.DateTimeFormat("th-TH", {
            year: "numeric",
            month: "long",
            timeZone: "Asia/Bangkok",
          });
          return `เดือน ${df.format(datum.x)}`;
        } else {
          return null;
        }
      } else if (datum.type === CrimeTypes["drug"]) {
        const df = new Intl.DateTimeFormat("th-TH", {
          year: "numeric",
          month: "long",
          timeZone: "Asia/Bangkok",
        });
        return `เดือน ${df.format(datum.x)}`;
      } else {
        return null;
      }
    }
    return `${datum.type} ${datum.y} คดี`;
  };

  useEffect(() => {
    const filter = { ...addressFilter, ...typeFilter };
    if (Object.keys(filter).length) {
      fetchReport(filter);
    }
  }, [addressFilter, typeFilter]);

  useEffect(() => {
    const chartData = getChartData(eventReport);
    setEventChartData(chartData);
  }, [eventReport]);

  return (
    <div className="w-50 me-4">
      <h5>
        จำนวนการแจ้งข้อมูลข่าวสารเพื่อความปลอดภัย แยกตามประเภทคดีอาชญากรรม{" "}
      </h5>
      <VictoryChart
        width={600}
        height={400}
        scale={{ x: "time" }}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => getHoverLabel(datum)}
            labelComponent={
              <VictoryTooltip
                cornerRadius={0}
                flyoutStyle={{ fill: "white" }}
              />
            }
          />
        }
      >
        <VictoryLegend
          x={100}
          y={0}
          orientation="horizontal"
          gutter={20}
          style={{ border: { stroke: "black" }, title: { fontSize: 20 } }}
          data={Object.keys(CrimeTypes).map((type) => ({
            name: CrimeTypes[type],
            symbol: { fill: CrimeTypesColor[type] },
          }))}
        />
        <VictoryAxis
          domain={getXDomain()}
          tickFormat={(date) => {
            const df = new Intl.DateTimeFormat("th-TH", {
              year: "2-digit",
              month: "short",
              timeZone: "Asia/Bangkok",
            }); // YYYY-MM-DD
            return df.format(date);
          }}
        />

        <VictoryAxis dependentAxis domain={{ y: [0, 10] }} />
        <VictoryGroup>
          {Object.keys(eventChartData).map((type, idx) => {
            if (typeFilter?.eventTypes?.length) {
              if (typeFilter?.eventTypes?.includes(type)) {
                return (
                  <VictoryLine
                    key={idx}
                    data={eventChartData[type]}
                    style={{ data: { stroke: CrimeTypesColor[type] } }}
                  />
                );
              }
              return null;
            }
            return (
              <VictoryLine
                key={idx}
                data={eventChartData[type]}
                style={{ data: { stroke: CrimeTypesColor[type] } }}
              />
            );
          })}
        </VictoryGroup>
        {Object.keys(eventChartData).map((type, idx) => {
          if (typeFilter?.eventTypes?.length) {
            if (typeFilter?.eventTypes?.includes(type)) {
              return (
                <VictoryScatter
                  key={idx}
                  data={eventChartData[type]}
                  size={3}
                  style={{ data: { fill: CrimeTypesColor[type] } }}
                />
              );
            }
            return null;
          }
          return (
            <VictoryScatter
              key={idx}
              data={eventChartData[type]}
              size={3}
              style={{ data: { fill: CrimeTypesColor[type] } }}
            />
          );
        })}
      </VictoryChart>
    </div>
  );
};

export default LineNotiDashboard;
