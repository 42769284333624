import { useState, useEffect } from "react";
import { ApiHelper } from "../libs/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const AddressFilter = ({ onChange }) => {
  const [addressOptions, setAddressOptions] = useState({
    regions: [],
    provinces: [],
    districts: [],
    tambons: [],
  });

  const [formValue, setFormValue] = useState({
    regions: [],
    provinces: [],
    districts: [],
    tambons: [],
  });

  const fetchAddress = async ({ regions, provinces, districts }) => {
    const p1 = new Promise((resolve, reject) => {
      ApiHelper.getRegions().then(({ success, data }) => {
        if (success) {
          const regions = data?.data.map(({ region }) => ({
            value: region,
            label: region,
          }));
          resolve(regions);
        } else {
          resolve([]);
        }
      });
    });
    const p2 = new Promise((resolve, reject) => {
      ApiHelper.getProvinces({ regions }).then(({ success, data }) => {
        if (success) {
          const provinces = data?.data.map(({ province_th }) => ({
            value: province_th,
            label: province_th,
          }));
          resolve(provinces);
        } else {
          resolve([]);
        }
      });
    });
    const p3 = new Promise((resolve, reject) => {
      ApiHelper.getDistricts({ regions, provinces }).then(
        ({ success, data }) => {
          if (success) {
            const districts = data?.data.map(({ district_th_short }) => ({
              value: district_th_short,
              label: district_th_short,
            }));
            resolve(districts);
          } else {
            resolve([]);
          }
        }
      );
    });
    const p4 = new Promise((resolve, reject) => {
      ApiHelper.getTambons({ regions, provinces, districts }).then(
        ({ success, data }) => {
          if (success) {
            const tambons = data?.data.map(({ tambon_th_short }) => ({
              value: tambon_th_short,
              label: tambon_th_short,
            }));
            resolve(tambons);
          } else {
            resolve([]);
          }
        }
      );
    });
    const values = await Promise.all([p1, p2, p3, p4]);
    setAddressOptions({
      ...addressOptions,
      regions: values[0],
      provinces: values[1],
      districts: values[2],
      tambons: values[3],
    });
  };

  useEffect(() => {
    fetchAddress(formValue);
    onChange(formValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue]);

  return (
    <div className="d-flex flex-row justify-content-center my-2">
      <div className="w-25 p-2 ms-2">
        <p className="mb-0">ภาค</p>
        <Select
          isMulti
          name="regions"
          placeholder="ทุกภาค"
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={addressOptions.regions}
          onChange={(values) => {
            const regions = values.map(({ value }) => value);
            setFormValue({ ...formValue, regions });
          }}
        />
      </div>
      <div className="w-25 p-2">
        <p className="mb-0">จังหวัด</p>
        <Select
          isMulti
          name="provinces"
          placeholder="ทุกจังหวัด"
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={addressOptions.provinces}
          onChange={(values) => {
            const provinces = values.map(({ value }) => value);
            setFormValue({ ...formValue, provinces });
          }}
        />
      </div>
      <div className="w-25 p-2">
        <p className="mb-0">อำเภอ/เขต</p>
        <Select
          isMulti
          name="districts"
          placeholder="ทุกอำเภอ/เขต"
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={addressOptions.districts}
          onChange={(values) => {
            const districts = values.map(({ value }) => value);
            setFormValue({ ...formValue, districts });
          }}
        />
      </div>
      <div className="w-25 p-2 me-2">
        <p className="mb-0">ตำบล/แขวง</p>
        <Select
          isMulti
          name="tambons"
          placeholder="ทุกตำบล/แขวง"
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={addressOptions.tambons}
          onChange={(values) => {
            const tambons = values.map(({ value }) => value);
            setFormValue({ ...formValue, tambons });
          }}
        />
      </div>
    </div>
  );
};
export default AddressFilter;
