import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./libs/auth";
import { CrimeEmergency, CrimeVerify, Home, Login } from "./pages";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/crimeverify" element={<CrimeVerify />} />
          <Route exact path="/crimeemergency" element={<CrimeEmergency />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
