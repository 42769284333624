export const API_URL =
  process.env.REACT_APP_API_URL ||
  window.env.API_URL ||
  "http://localhost:8055";

export const DEFAULT_ERROR_MESSAGE = "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง";

export const BOARD_ROLE =
  process.env.REACT_APP_BOARD_ROLE ||
  window.env.BOARD_ROLE ||
  "78471244-7a6f-4bcd-acea-eeaf59414fc6";
