import React, { useState, useEffect } from "react";
import {
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLegend,
  VictoryAxis,
} from "victory";
import { ApiHelper } from "../libs/api";

const RegionTypes = {
  ภาคกลาง: "กลาง",
  ภาคเหนือ: "เหนือ",
  ภาคตะวันออกเฉียงเหนือ: "ตะวันออกเฉียงเหนือ",
  ภาคตะวันออก: "ตะวันออก",
  ภาคตะวันตก: "ตะวันตก",
  ภาคใต้: "ใต้",
};

const RegionTypesColor = {
  ภาคกลาง: "#00798C",
  ภาคเหนือ: "#E63946",
  ภาคตะวันออกเฉียงเหนือ: "#EDAE49",
  ภาคตะวันออก: "#3376BD",
  ภาคตะวันตก: "#E646BD",
  ภาคใต้: "#B276ED",
};

const LineEmergencyDashboard = ({ addressFilter = {}, typeFilter = {} }) => {
  const [eventChartData, setEventChartData] = useState({});
  const [eventReport, setEventReport] = useState({});

  const fetchReport = async (filter) => {
    const {
      regions = [],
      provinces = [],
      districts = [],
      tambons = [],
      peopleTypes = [],
      damageTypes = [],
      verifyTypes = [],
      eventTypes = [],
      startDate,
      endDate,
    } = filter;

    const reportData = await ApiHelper.getEventRegionReport({
      regions,
      provinces,
      districts,
      tambons,
      genders: peopleTypes,
      damages: damageTypes,
      verified: verifyTypes,
      types: eventTypes,
      startDate,
      endDate,
    });

    if (reportData.success) {
      setEventReport(reportData.data);
    } else {
      setEventReport({});
    }
  };

  const getChartData = (report) => {
    const chartData = {};
    if (Object.keys(report).length) {
      const months = Object.keys(report).sort();
      for (let month of months) {
        if ("crime_count" in report[month]) {
          for (let region in report[month].crime_count) {
            if (!(region in chartData)) {
              chartData[region] = [];
            }
            const [year, m] = month.split("-");
            chartData[region].push({
              x: new Date(year, m - 1, 1),
              y: report[month].crime_count[region],
              region: region,
            });
          }
        }
      }
    }
    return chartData;
  };

  const getXDomain = () => {
    const minDate = new Date();
    if (typeFilter?.duration) {
      minDate.setMonth(minDate.getMonth() - typeFilter?.duration);
    }
    minDate.setDate(1);

    const maxDate = typeFilter?.endDate
      ? new Date(typeFilter.endDate)
      : new Date();
    maxDate.setDate(1);

    return { x: [minDate, maxDate] };
  };

  const getHoverLabel = (datum) => {
    if (datum.childName.includes("line")) {
      if (addressFilter?.regions?.length) {
        if (addressFilter.regions[0] === datum.region) {
          const df = new Intl.DateTimeFormat("th-TH", {
            year: "numeric",
            month: "long",
            timeZone: "Asia/Bangkok",
          });
          return `เดือน ${df.format(datum.x)}`;
        } else {
          return null;
        }
      } else if (datum.region === "ภาคกลาง") {
        const df = new Intl.DateTimeFormat("th-TH", {
          year: "numeric",
          month: "long",
          timeZone: "Asia/Bangkok",
        });
        return `เดือน ${df.format(datum.x)}`;
      } else {
        return null;
      }
    }
    return `${datum.region} ${datum.y} คดี`;
  };

  useEffect(() => {
    const filter = { ...addressFilter, ...typeFilter };
    if (Object.keys(filter).length) {
      fetchReport(filter);
    }
  }, [addressFilter, typeFilter]);

  useEffect(() => {
    setEventChartData(getChartData(eventReport));
  }, [eventReport]);

  return (
    <div className="w-50">
      <h5>จำนวนการแจ้งข้อมูลข่าวสารเพื่อความปลอดภัย แยกตามภูมิภาค </h5>
      <VictoryChart
        width={600}
        height={400}
        scale={{ x: "time" }}
        containerComponent={
          <VictoryVoronoiContainer
            voronoiDimension="x"
            labels={({ datum }) => getHoverLabel(datum)}
            labelComponent={
              <VictoryTooltip
                cornerRadius={0}
                flyoutStyle={{ fill: "white" }}
              />
            }
          />
        }
      >
        <VictoryLegend
          x={40}
          y={0}
          orientation="horizontal"
          gutter={20}
          style={{ border: { stroke: "black" }, title: { fontSize: 20 } }}
          data={Object.keys(RegionTypesColor).map((region) => ({
            name: RegionTypes[region],
            symbol: { fill: RegionTypesColor[region] },
          }))}
        />
        <VictoryAxis
          domain={getXDomain()}
          tickFormat={(date) => {
            const df = new Intl.DateTimeFormat("th-TH", {
              year: "2-digit",
              month: "short",
              timeZone: "Asia/Bangkok",
            }); // YYYY-MM-DD
            return df.format(date);
          }}
        />
        <VictoryAxis dependentAxis domain={{ y: [0, 10] }} />
        <VictoryGroup>
          {Object.keys(eventChartData).map((region, idx) => {
            if (addressFilter?.regions?.length) {
              if (addressFilter?.regions?.includes(region)) {
                return (
                  <VictoryLine
                    key={idx}
                    data={eventChartData[region]}
                    style={{ data: { stroke: RegionTypesColor[region] } }}
                  />
                );
              }
              return null;
            }
            return (
              <VictoryLine
                key={idx}
                data={eventChartData[region]}
                style={{ data: { stroke: RegionTypesColor[region] } }}
              />
            );
          })}
        </VictoryGroup>
        {Object.keys(eventChartData).map((region, idx) => {
          if (addressFilter?.regions?.length) {
            if (addressFilter?.regions?.includes(region)) {
              return (
                <VictoryScatter
                  key={idx}
                  data={eventChartData[region]}
                  size={3}
                  style={{ data: { fill: RegionTypesColor[region] } }}
                />
              );
            }
            return null;
          }
          return (
            <VictoryScatter
              key={idx}
              data={eventChartData[region]}
              size={3}
              style={{ data: { fill: RegionTypesColor[region] } }}
            />
          );
        })}
      </VictoryChart>
    </div>
  );
};

export default LineEmergencyDashboard;
