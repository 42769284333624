import { useState, useContext } from "react";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { AuthContext, AuthHelper } from "../libs/auth";

import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBCollapse,
  MDBIcon,
  MDBNavbarNav,
} from "mdb-react-ui-kit";

const Navbar = () => {
  const { user, checkAuth } = useContext(AuthContext);
  const [showNav, setShowNav] = useState(false);

  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    const { success } = await AuthHelper.logout();
    if (!success) {
      localStorage.removeItem("auth_token");
    }
    await checkAuth();
    navigate("/");
  };

  return (
    <>
      <MDBNavbar expand="lg" light bgColor="light">
        <MDBContainer fluid>
          <Link className="navbar-brand" to="/">
            <img src="logo192.png" height="30" alt="" loading="lazy" />
            Safe Points Dashboard
          </Link>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarToggler"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNav(!showNav)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse navbar show={showNav}>
            <MDBNavbarNav left fullWidth={false} className="mb-2 mb-lg-0">
              <MDBNavbarItem>
                <NavLink className="nav-link" to="/">
                  จำนวนคดีการเกิดอาชญากรรม
                </NavLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <NavLink className="nav-link" to="/crimeverify">
                  รายงานการแจ้งข้อมูลข่าวสารเพื่อความปลอดภัยจากแอปพลิเคชัน
                </NavLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <NavLink className="nav-link" to="/crimeemergency">
                  จำนวนการแจ้งเหตุด่วนให้ประชาชน
                </NavLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
            {user ? (
              <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <NavLink
                    className="nav-link"
                    to="/logout"
                    onClick={handleLogout}
                  >
                    ออกจากระบบ
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            ) : (
              <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
                <MDBNavbarItem>
                  <NavLink className="nav-link" to="/login">
                    เข้าสู่ระบบ
                  </NavLink>
                </MDBNavbarItem>
              </MDBNavbarNav>
            )}
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};

export default Navbar;
