import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthHelper, AuthContext } from "../../libs/auth";
import { DEFAULT_ERROR_MESSAGE } from "../../libs/constants";
import { ModalAlert, Layout } from "../../components";

import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBValidation,
  MDBInput,
} from "mdb-react-ui-kit";

const Login = () => {
  const { user, checkAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });
  const [modalValue, setModalValue] = useState({
    modalTitle: "",
    modalMessage: "",
    showModal: false,
    onModalClose: () => {},
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formValue;
    if (email && email.includes("@") && !email.endsWith("@") && password) {
      const { success, error } = await AuthHelper.login(email, password);
      if (success) {
        const { success: authSuccess, error: authError } = await checkAuth();
        if (authSuccess) {
          navigate("/");
        } else {
          let errorMsg = DEFAULT_ERROR_MESSAGE;
          if (authError === "You are not authorized to access this page") {
            errorMsg = "บัญชีผู้ใช้ของคุณไม่มีสิทธิ์ในการใช้งานระบบนี้";
          }
          setModalValue({
            modalTitle: "เกิดข้อผิดพลาด",
            modalMessage: errorMsg,
            showModal: true,
            onModalClose: () =>
              setModalValue({ ...modalValue, showModal: false }),
          });
        }
      } else {
        const message = error?.message?.toLowerCase();
        let errorMsg = DEFAULT_ERROR_MESSAGE;
        if (message.includes("invalid user")) {
          errorMsg = "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง";
        } else if (message.includes("email")) {
          errorMsg = "อีเมลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง";
        }
        setModalValue({
          modalTitle: "เกิดข้อผิดพลาด",
          modalMessage: errorMsg,
          showModal: true,
          onModalClose: () =>
            setModalValue({ ...modalValue, showModal: false }),
        });
      }
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Layout>
      <MDBContainer className="d-flex align-items-center justify-content-center">
        <MDBCard
          className="m-5"
          style={{ maxWidth: "600px", minWidth: "400px" }}
        >
          <MDBCardBody className="px-5">
            <h2 className="text-uppercase text-center mb-5">เข้าสู่ระบบ</h2>
            <MDBValidation onSubmit={handleSubmit}>
              <MDBInput
                wrapperClass="mb-4"
                label="อีเมล"
                size="lg"
                id="input-email"
                type="email"
                name="email"
                value={formValue.email}
                onChange={onChange}
                required
              />
              <MDBInput
                wrapperClass="mb-4"
                label="รหัสผ่าน"
                size="lg"
                id="input-password"
                type="password"
                name="password"
                value={formValue.password}
                onChange={onChange}
                required
              />
              <MDBBtn type="submit" className="mb-4 w-100" size="lg">
                เข้าสู่ระบบ
              </MDBBtn>
            </MDBValidation>
          </MDBCardBody>
        </MDBCard>
        <ModalAlert
          show={modalValue.showModal}
          setShow={(show) => (show ? null : modalValue.onModalClose())}
          title={modalValue.modalTitle}
          onClose={modalValue.onModalClose}
        >
          {modalValue.modalMessage}
        </ModalAlert>
      </MDBContainer>
    </Layout>
  );
};

export default Login;
