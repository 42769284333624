import React, { useState, useEffect } from "react";
import {
  VictoryPie,
  VictoryLegend,
  VictoryLabel,
  VictoryTooltip,
} from "victory";
import { ApiHelper } from "../libs/api";

const EmergencyDashboard = ({ addressFilter = {}, typeFilter = {} }) => {
  const [data, setData] = useState([]);

  const fetchData = async ({
    regions = [],
    provinces = [],
    districts = [],
    tambons = [],
    peopleTypes = [],
    damageTypes = [],
    eventTypes = [],
    startDate,
    endDate,
  }) => {
    const response = await ApiHelper.getCrimeNotiReport({
      regions,
      provinces,
      districts,
      tambons,
      genders: peopleTypes,
      damages: damageTypes,
      types: eventTypes,
      startDate,
      endDate,
    });
    if (response.success) {
      const transformedData = Object.keys(response.data).map((key) => {
        return {
          x: key.charAt(0).toUpperCase() + key.slice(1),
          y: response.data[key],
        };
      });
      setData(transformedData);
    }
  };

  useEffect(() => {
    const fetchReport = async (filter = {}) => {
      fetchData(filter);
    };
    const filter = { ...addressFilter, ...typeFilter };
    if (Object.keys(filter).length) {
      fetchReport(filter);
    }
  }, [addressFilter, typeFilter]);

  const total = data.reduce((acc, item) => acc + item.y, 0);

  const getTooltipContent = ({ datum }) => {
    let thaiName;
    switch (datum.x) {
      case "Citizen":
        thaiName = "ข้อมูลการแจ้งเหตุของประชาชน";
        break;
      case "Staff":
        thaiName = "ข้อมูลการแจ้งเหตุอื่นๆ";
        break;
      default:
        thaiName = datum.x;
    }

    const percentage = ((datum.y / total) * 100).toFixed(2);
    return `${thaiName}: ${datum.y} ครั้ง (${percentage}%)`;
  };

  return (
    <div className="w-50 me-4">
      <h5>จำนวนการแจ้งเตือนเหตุด่วนให้ประชาชน </h5>
      <div style={{ width: "100%", maxWidth: "550px", margin: "auto" }}>
        <svg viewBox="0 0 550 400">
          <VictoryPie
            standalone={false}
            data={data}
            innerRadius={88}
            labelRadius={150}
            labelComponent={
              <VictoryTooltip
                flyoutStyle={{ fill: "white" }}
                center={{ x: 200 }}
              />
            }
            labels={getTooltipContent}
            style={{
              data: {
                fillOpacity: 0.9,
                stroke: "#fff",
                strokeWidth: 2,
              },
            }}
            colorScale={["#3070F5", "#57BEB5"]}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 16 }}
            x={200}
            y={200}
            text={`จำนวนทั้งหมด(ครั้ง)\n${total}`}
          />
          <VictoryLegend
            standalone={false}
            x={320}
            y={20}
            orientation="vertical"
            gutter={20}
            style={{
              border: { stroke: "black" },
              title: { fontSize: 14 },
              labels: { width: 200 },
            }}
            data={data.map((item, index) => {
              let newName;
              switch (item.x) {
                case "Citizen":
                  newName = "ข้อมูลการแจ้งเหตุของประชาชน";
                  break;
                case "Staff":
                  newName = "ข้อมูลการแจ้งเหตุอื่นๆ";
                  break;
                default:
                  newName = item.x;
              }

              return {
                name: newName,
                symbol: {
                  fill: ["#3070F5", "#57BEB5"][
                    index % ["#3070F5", "#57BEB5"].length
                  ],
                },
              };
            })}
          />
        </svg>
      </div>
    </div>
  );
};

export default EmergencyDashboard;
