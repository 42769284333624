import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../libs/auth";
import {
  Layout,
  AddressFilter,
  TypeFilter,
  HomeTypeDashboard,
  HomeRegionDashboard,
} from "../../components";

const Home = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [addressFilter, setAddressFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Layout>
      <h4 className="m-3">Dashboard แสดงจำนวนคดีการเกิดอาชญากรรม</h4>
      <AddressFilter onChange={(value) => setAddressFilter(value)} />
      <TypeFilter onChange={(value) => setTypeFilter(value)} />
      <div className="d-flex flex-row justify-content-center m-4">
        <HomeTypeDashboard
          addressFilter={addressFilter}
          typeFilter={typeFilter}
        />
        <HomeRegionDashboard
          addressFilter={addressFilter}
          typeFilter={typeFilter}
        />
      </div>
    </Layout>
  );
};

export default Home;
