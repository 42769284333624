import { useState, useEffect } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import makeAnimated from "react-select/animated";
import "dayjs/locale/th";

dayjs.locale("th");

const animatedComponents = makeAnimated();

const toBuddhistYear = (date) => {
  return dayjs(date).add(543, "year");
};

const fromBuddhistYear = (date) => {
  return dayjs(date).subtract(543, "year");
};

const TypeFilter3 = ({ onChange }) => {
  const typeOptions = {
    eventTypes: [
      { label: "ชีวิตและร่างกาย", value: "life" },
      { label: "เพศ", value: "sex" },
      { label: "ทรัพย์", value: "property" },
      { label: "ยาเสพติด", value: "drug" },
      { label: "อื่นๆ", value: "custom" },
    ],
    durations: [
      { label: "6 เดือนย้อนหลัง", value: 6 },
      { label: "9 เดือนย้อนหลัง", value: 9 },
      { label: "1 ปีย้อนหลัง", value: 12 },
      { label: "2 ปีย้อนหลัง", value: 24 },
      { label: "3 ปีย้อนหลัง", value: 36 },
      { label: "4 ปีย้อนหลัง", value: 48 },
      { label: "5 ปีย้อนหลัง", value: 60 },
      { label: "เลือกช่วงเวลาเอง", value: "custom" },
    ],
    peopleTypes: [
      { label: "เพศชาย", value: "male" },
      { label: "เพศหญิง", value: "female" },
      { label: "ไม่ระบุเพศ", value: "none" },
    ],
    verifyTypes: [
      { label: "ยืนยันแล้ว", value: 1 },
      { label: "ยังไม่ยืนยัน", value: 0 },
    ],
    regionTypes: [
      { label: "ภาคกลาง", value: "ภาคกลาง" },
      { label: "ภาคเหนือ", value: "ภาคเหนือ" },
      { label: "ภาคตะวันออกเฉียงเหนือ", value: "ภาคตะวันออกเฉียงเหนือ" },
      { label: "ภาคตะวันออก", value: "ภาคตะวันออก" },
      { label: "ภาคตะวันตก", value: "ภาคตะวันตก" },
      { label: "ภาคใต้", value: "ภาคใต้" },
    ],
  };

  const [formValue, setFormValue] = useState({
    eventTypes: [],
    duration: typeOptions.durations[0].value,
    verifyTypes: [],
  });

  const [showDatePickers, setShowDatePickers] = useState(false);
  const [customDateRange, setCustomDateRange] = useState({
    startDate: dayjs().subtract(6, "month").toDate(),
    endDate: new Date(),
  });

  const handleDurationChange = ({ value }) => {
    if (value === "custom") {
      setShowDatePickers(true);
      const durationMonths = dayjs(customDateRange.endDate).diff(
        customDateRange.startDate,
        "month"
      );
      setFormValue({ ...formValue, duration: durationMonths });
    } else {
      setShowDatePickers(false);
      setFormValue({ ...formValue, duration: value });
    }
  };

  const handleDateChange = (dateType, dateValue) => {
    const updatedRange = {
      ...customDateRange,
      [dateType]: fromBuddhistYear(dateValue).toDate(),
    };
    setCustomDateRange(updatedRange);

    const durationMonths = dayjs(updatedRange.endDate).diff(
      updatedRange.startDate,
      "month"
    );
    setFormValue({ ...formValue, duration: durationMonths });
  };

  useEffect(() => {
    const updatedValue = {
      ...formValue,
      startDate: customDateRange.startDate,
      endDate: customDateRange.endDate,
    };
    onChange(updatedValue);
  }, [formValue, customDateRange, onChange]);

  return (
    <div>
      <div className="d-flex flex-row justify-content-start my-2">
        {/* Event Types Select */}
        <div className="w-25 p-2 ms-2">
          <p className="mb-0">ประเภทคดี</p>
          <Select
            isMulti
            name="eventTypes"
            placeholder="ทุกประเภท"
            closeMenuOnSelect={false}
            components={animatedComponents}
            options={typeOptions.eventTypes}
            onChange={(values) => {
              const eventTypes = values.map(({ value }) => value);
              setFormValue({ ...formValue, eventTypes });
            }}
          />
        </div>

        {/* Duration Select */}
        <div className="w-25 p-2">
          <p className="mb-0">ช่วงเวลา</p>
          <Select
            name="duration"
            placeholder="ทุกช่วงเวลา"
            closeMenuOnSelect={false}
            components={animatedComponents}
            options={typeOptions.durations}
            defaultValue={typeOptions.durations[0]}
            onChange={handleDurationChange}
          />
        </div>

        {/* Start Date Picker */}
        {showDatePickers && (
          <div className="w-25 p-2">
            <p className="mb-0">วันที่เริ่มต้น</p>
            <div className="react-select-like">
              <DatePicker
                selected={toBuddhistYear(customDateRange.startDate).toDate()}
                onChange={(date) => handleDateChange("startDate", date)}
                dateFormat="dd/MM/yyyy"
                locale="th"
                wrapperClassName="date-picker-wrapper"
                className="form-control"
              />
            </div>
          </div>
        )}

        {/* End Date Picker */}
        {showDatePickers && (
          <div className="w-25 p-2">
            <p className="mb-0">วันที่สิ้นสุด</p>
            <div className="react-select-like">
              <DatePicker
                selected={toBuddhistYear(customDateRange.endDate).toDate()}
                onChange={(date) => handleDateChange("endDate", date)}
                dateFormat="dd/MM/yyyy"
                locale="th"
                wrapperClassName="date-picker-wrapper"
                className="form-control"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TypeFilter3;
