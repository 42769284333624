import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../libs/auth";
import {
  Layout,
  AddressFilter,
  TypeFilter3,
  PieEmergencyDashboard,
  EmergencyRegionDashboard,
  EmergencyTypeDashboard,
} from "../../components";

const CrimeEmergency = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [addressFilter, setAddressFilter] = useState(null);
  const [typeFilter3, setTypeFilter3] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Layout>
      <h4 className="m-3">Dashboard แสดงจำนวนการแจ้งเตือนเหตุด่วนให้ประชาชน</h4>
      <AddressFilter onChange={(value) => setAddressFilter(value)} />
      <TypeFilter3 onChange={(value) => setTypeFilter3(value)} />
      <div className="d-flex flex-row justify-content-center m-4">
        <PieEmergencyDashboard
          addressFilter={addressFilter}
          typeFilter={typeFilter3}
        />
      </div>
      <div className="d-flex flex-row justify-content-center m-4">
        <EmergencyTypeDashboard
          addressFilter={addressFilter}
          typeFilter={typeFilter3}
        />
        <EmergencyRegionDashboard
          addressFilter={addressFilter}
          typeFilter={typeFilter3}
        />
      </div>
    </Layout>
  );
};

export default CrimeEmergency;

// import { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../libs/auth";
// import {
//   Layout,
//   AddressFilter,
//   TypeFilter3,
//   PieEmergencyDashboard,
//   LineEmergencyDashboard,
//   EmergencyRegionDashboard,
// } from "../../components";

// const CrimeEmergency = () => {
//   const { user } = useContext(AuthContext);
//   const navigate = useNavigate();
//   const [addressFilter, setAddressFilter] = useState(null);
//   const [typeFilter3, setTypeFilter3] = useState(null);

//   useEffect(() => {
//     if (!user) {
//       navigate("/login");
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [user]);

//   return (
//     <Layout>
//       <h4 className="m-3">Dashboard แสดงจำนวนการแจ้งเตือนเหตุด่วนให้ประชาชน</h4>
//       <AddressFilter onChange={(value) => setAddressFilter(value)} />
//       <TypeFilter3 onChange={(value) => setTypeFilter3(value)} />
//       <PieEmergencyDashboard
//         addressFilter={addressFilter}
//         typeFilter={typeFilter3}
//       />
//       <div className="d-flex flex-row justify-content-center m-4">
//         <LineEmergencyDashboard
//           addressFilter={addressFilter}
//           typeFilter={typeFilter3}
//         />
//         <EmergencyRegionDashboard typeFilter={typeFilter3} />
//       </div>
//     </Layout>
//   );
// };

// export default CrimeEmergency;
